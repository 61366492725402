import { Navigate, RouteObject } from 'react-router-dom';

import { IconName } from 'Components/Atoms/Icon';

import CatalogShow from 'Pages/App/Catalogs/CatalogShow';
import CatalogsIndex from 'Pages/App/Catalogs/CatalogsIndex';
import Contacts from 'Pages/App/Contacts/ContactsIndex';
import ContactTransportFees from 'Pages/App/Contacts/TransportFees/ContactTransportFees';
import Dashboard from 'Pages/App/Dashboard/DashboardIndex';
import EmailTemplates from 'Pages/App/EmailTemplates/EmailTemplatesIndex';
import FeatureRequestsIndex from 'Pages/App/FeatureRequests/FeatureRequestsIndex';
import FeatureRequestsList from 'Pages/App/FeatureRequests/FeatureRequestsList';
import FeatureRequestsShow from 'Pages/App/FeatureRequests/FeatureRequestsShow';
import GalleriesArchived from 'Pages/App/Galleries/GalleriesArchived';
import Galleries from 'Pages/App/Galleries/GalleriesIndex';
import GalleriesPortfolio from 'Pages/App/Galleries/GalleriesPortfolio';
import GalleriesSettings from 'Pages/App/Galleries/GalleriesSettings';
import Gallery from 'Pages/App/Galleries/GalleriesShow';
import GalleriesTrash from 'Pages/App/Galleries/GalleriesTrash';
import GalleryPresetShow from 'Pages/App/GalleryPresets/GalleryPresetShow';
import GalleryPresetsIndex from 'Pages/App/GalleryPresets/GalleryPresetsIndex';
import InvoicesIndex from 'Pages/App/Invoices/InvoicesIndex';
import LaboOrders from 'Pages/App/Orders/LaboOrders';
import Orders from 'Pages/App/Orders/OrdersIndex';
import Order from 'Pages/App/Orders/OrdersShow';
import ProductCategories from 'Pages/App/ProductCategories/ProductCategoriesIndex';
import ProductsFotostudio from 'Pages/App/Products/ProductsFotostudio';
import Products from 'Pages/App/Products/ProductsIndex';
import ProductShow from 'Pages/App/Products/ProductsShow';
import Appearance from 'Pages/App/Settings/Appearance';
import EmailConfig from 'Pages/App/Settings/EmailConfig';
import MyActivity from 'Pages/App/Settings/MyActivity';
import Payments from 'Pages/App/Settings/Payments';
import Watermarks from 'Pages/App/Watermarks/WatermarksIndex';
import NotFound from 'Pages/Errors/NotFound';

export type AppRoutePathsObject = RouteObject & {
  title?: string;
  titleParams?: { [key: string]: string | number };
  icon?: IconName;
  isVisible: boolean;
  allowedRoles?: string[];
  disallowedRoles?: string[];
  children?: AppRoutePathsObject[];
  isSeparator?: boolean;
};

interface AppRouteObject {
  namespace: string;
  title: string;
  icon: IconName;
  paths?: AppRoutePathsObject[];
  href?: string;
  inMainMenu: boolean;
  disallowedRoles?: string[];
}

const notFound: AppRoutePathsObject = {
  path: '*',
  isVisible: false,
  element: <NotFound />,
};

export const appRoutes: AppRouteObject[][] = [
  [
    {
      namespace: 'home',
      title: 'app.menu.home',
      icon: 'home',
      inMainMenu: true,
      disallowedRoles: ['incognito'],
      paths: [
        {
          isVisible: false,
          element: <Navigate to={'dashboard'} />,
          index: true,
        },
        {
          title: 'app.menu.dashboard',
          icon: 'dashboard',
          path: 'dashboard',
          element: <Dashboard />,
          isVisible: true,
        },
      ],
    },
    {
      namespace: 'galleries',
      title: 'app.common.galleries',
      icon: 'galleries',
      inMainMenu: true,
      disallowedRoles: ['incognito'],
      paths: [
        {
          path: 'galleries',
          title: 'app.menu.lastGalleries',
          icon: 'galleries',
          isVisible: true,
          children: [
            {
              element: <Galleries />,
              isVisible: false,
              index: true,
            },
            {
              path: ':id',
              isVisible: false,
              element: <Gallery />,
            },
            {
              title: 'app.common.galleryPresets',
              icon: 'preset',
              path: 'presets',
              isVisible: true,
              children: [
                {
                  element: <GalleryPresetsIndex />,
                  isVisible: false,
                  index: true,
                },
                {
                  path: ':id',
                  isVisible: false,
                  element: <GalleryPresetShow />,
                },
              ] as AppRoutePathsObject[],
            },
            {
              title: 'app.common.watermarks',
              icon: 'watermarks',
              path: 'watermarks',
              isVisible: true,
              element: <Watermarks />,
            },
            {
              title: 'app.common.portfolio',
              icon: 'globe',
              path: 'portfolio',
              isVisible: true,
              element: <GalleriesPortfolio />,
            },
            {
              isVisible: true,
              isSeparator: true,
            },
            {
              title: 'app.menu.archived',
              icon: 'archive',
              path: 'archived',
              isVisible: true,
              element: <GalleriesArchived />,
            },
            {
              title: 'app.common.trash',
              icon: 'delete',
              path: 'trash',
              isVisible: true,
              element: <GalleriesTrash />,
            },
            {
              title: 'app.common.galleriesSettings',
              icon: 'settings',
              path: 'settings',
              isVisible: true,
              element: <GalleriesSettings />,
            },
          ] as AppRoutePathsObject[],
        },
      ],
    },
    {
      namespace: 'shop',
      title: 'app.common.shop',
      icon: 'cart',
      inMainMenu: true,
      disallowedRoles: ['incognito'],
      paths: [
        {
          path: 'products',
          title: 'app.common.products',
          titleParams: { count: 2 },
          icon: 'shopping-bag',
          isVisible: true,
          children: [
            {
              element: <Products />,
              isVisible: false,
              index: true,
            },
            {
              path: ':id',
              isVisible: false,
              element: <ProductShow />,
            },
          ] as AppRoutePathsObject[],
        },
        {
          path: 'fotostudio-products',
          title: 'app.common.fotostudioProducts',
          titleParams: { count: 2 },
          icon: 'shootings',
          isVisible: true,
          children: [
            {
              element: <ProductsFotostudio />,
              isVisible: false,
              index: true,
            },
            {
              path: ':id',
              isVisible: false,
              element: <ProductShow />,
            },
          ] as AppRoutePathsObject[],
        },
        {
          path: 'catalogs',
          title: 'app.common.catalog',
          titleParams: { count: 2 },
          icon: 'book',
          isVisible: true,
          children: [
            {
              element: <CatalogsIndex />,
              isVisible: false,
              index: true,
            },
            {
              path: ':id/:category?',
              isVisible: false,
              element: <CatalogShow />,
            },
          ] as AppRoutePathsObject[],
        },
        {
          title: 'app.common.productCategories',
          titleParams: { count: 2 },
          icon: 'tags',
          path: 'products-categories',
          isVisible: true,
          element: <ProductCategories />,
        },
      ],
    },
    {
      namespace: 'orders',
      title: 'app.common.order',
      icon: 'purchased',
      inMainMenu: true,
      paths: [
        {
          path: 'orders',
          title: 'app.common.order',
          titleParams: { count: 2 },
          icon: 'purchased',
          isVisible: true,
          children: [
            {
              element: <Orders />,
              isVisible: false,
              index: true,
              disallowedRoles: ['incognito'],
            },
            {
              path: 'status/:status?',
              element: <Orders />,
              isVisible: false,
              disallowedRoles: ['incognito'],
            },
            {
              path: ':id',
              isVisible: false,
              element: <Order />,
              disallowedRoles: ['incognito'],
            },
            {
              path: 'fotostudio',
              title: 'app.orders.allOrders',
              titleParams: { count: 2 },
              icon: 'shootings',
              isVisible: true,
              allowedRoles: ['sales', 'admin', 'incognito'],
              children: [
                {
                  element: <Orders />,
                  isVisible: false,
                  index: true,
                },
                {
                  path: ':id',
                  isVisible: false,
                  element: <Order />,
                },
              ] as AppRoutePathsObject[],
            },
            {
              path: 'laboOrders',
              title: 'app.laboOrder.title',
              titleParams: { count: 2 },
              icon: 'file',
              isVisible: true,
              allowedRoles: ['sales', 'admin', 'incognito'],
              element: <LaboOrders />,
            },
          ] as AppRoutePathsObject[],
        },
      ],
    },
  ],
  [
    {
      namespace: 'contacts',
      title: 'app.common.contacts',
      icon: 'contacts',
      inMainMenu: true,
      disallowedRoles: ['incognito'],
      paths: [
        {
          path: 'contacts',
          title: 'app.common.contacts',
          icon: 'contacts-list',
          isVisible: true,
          children: [
            {
              element: <Contacts />,
              isVisible: false,
              index: true,
            },
            {
              path: ':id/transport-fees',
              isVisible: false,
              element: <ContactTransportFees />,
            },
          ] as AppRoutePathsObject[],
        },
      ],
    },
    {
      namespace: 'invoices',
      title: 'app.common.invoices',
      icon: 'subscription',
      inMainMenu: true,
      disallowedRoles: ['incognito'],
      paths: [
        {
          title: 'app.common.invoices',
          titleParams: { count: 2 },
          icon: 'subscription',
          path: 'invoices',
          isVisible: true,
          element: <InvoicesIndex />,
          index: true,
        },
      ],
    },
  ],
  [
    {
      namespace: 'data',
      title: 'app.common.data',
      icon: 'data',
      inMainMenu: true,
      disallowedRoles: ['incognito'],
      paths: [
        {
          title: 'app.common.emailTemplates',
          titleParams: { count: 2 },
          icon: 'emails',
          path: 'data/email-templates',
          isVisible: true,
          element: <EmailTemplates />,
          index: true,
        },
      ],
    },
  ],
  [
    {
      namespace: 'feature-requests',
      title: 'app.common.featureRequest.list',
      icon: 'bulb',
      inMainMenu: true,
      disallowedRoles: ['incognito'],
      paths: [
        {
          path: 'feature-requests',
          title: 'app.common.featureRequest.list',
          icon: 'list',
          isVisible: true,
          children: [
            {
              element: <FeatureRequestsList />,
              isVisible: false,
              index: true,
            },
            {
              title: 'app.common.featureRequest.list',
              icon: 'bulb',
              path: ':id',
              isVisible: false,
              element: <FeatureRequestsShow />,
            },
            {
              title: 'app.common.featureRequest.list',
              icon: 'list',
              path: 'category/:category',
              isVisible: false,
              element: <FeatureRequestsList />,
            },
            {
              title: 'app.common.featureRequest.table',
              icon: 'kanban',
              path: 'table',
              isVisible: true,
              element: <FeatureRequestsIndex />,
            },
          ] as AppRoutePathsObject[],
        },
      ],
    },

    {
      namespace: 'help',
      title: 'app.common.help',
      disallowedRoles: ['incognito'],
      icon: 'help',
      inMainMenu: true,
      href: 'https://help.fotostudio.io/',
    },
  ],
  [
    {
      namespace: 'settings',
      title: 'app.common.settings',
      icon: 'settings',
      inMainMenu: false,
      disallowedRoles: ['incognito'],
      paths: [
        {
          path: 'settings',
          isVisible: false,
          children: [
            {
              path: '',
              isVisible: false,
              element: <Navigate to={'my-activity'} />,
            },
            {
              title: 'app.settings.myActivity',
              icon: 'my-activity',
              path: 'my-activity',
              isVisible: true,
              element: <MyActivity />,
            },
            {
              title: 'app.settings.emailConfig.title',
              icon: 'emails',
              path: 'email-config',
              isVisible: true,
              element: <EmailConfig />,
            },
            {
              title: 'app.settings.payments.title',
              icon: 'cards',
              path: 'payments',
              isVisible: true,
              element: <Payments />,
            },
            {
              title: 'app.settings.appearance.title',
              icon: 'preset',
              path: 'appearance',
              isVisible: true,
              element: <Appearance />,
            },
          ] as AppRoutePathsObject[],
        },
      ],
    },
  ],
];
