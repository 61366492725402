import React from 'react';

import { Space } from 'antd';

import { IconBaseProps, IconType } from 'react-icons';
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillMessage,
  AiFillStar,
  AiOutlineComment,
  AiOutlineExclamationCircle,
  AiOutlineFile,
  AiOutlineFileAdd,
  AiOutlineFilePdf,
  AiOutlineGoogle,
  AiOutlineNotification,
  AiOutlineShoppingCart,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
  AiOutlineUser
} from 'react-icons/ai';
import {
  BiBlock,
  BiCopyright,
  BiDuplicate,
  BiErrorCircle,
  BiListOl,
  BiMessageRoundedDetail,
  BiPaint,
  BiTargetLock
} from 'react-icons/bi';
import {
  BsBoxSeam,
  BsCart,
  BsGlobe2,
  BsGrid3X2Gap,
  BsKanban,
  BsTable,
  BsTruck,
  BsUiChecksGrid
} from 'react-icons/bs';
import { CgMore, CgViewList } from 'react-icons/cg';
import {
  FaBinoculars,
  FaCheckSquare,
  FaCubes,
  FaEuroSign,
  FaRandom,
  FaRegHandshake
} from 'react-icons/fa';
import { FaCircleInfo } from 'react-icons/fa6';
import { FiCopy, FiUploadCloud } from 'react-icons/fi';
import {
  GoLightBulb,
  GoSidebarCollapse,
  GoSidebarExpand
} from 'react-icons/go';
import { GrStatusCriticalSmall } from 'react-icons/gr';
import {
  HiExternalLink,
  HiOutlineArchive,
  HiOutlineDocument,
  HiOutlineDownload,
  HiOutlineHeart,
  HiOutlineLocationMarker,
  HiPlus
} from 'react-icons/hi';
import { IoIosSend, IoMdPhotos, IoMdPricetag } from 'react-icons/io';
import { IoAdd, IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { LuStar, LuStarOff } from 'react-icons/lu';
import {
  MdOutlineAdminPanelSettings,
  MdOutlineExitToApp,
  MdOutlineShoppingBag,
  MdOutlineTipsAndUpdates
} from 'react-icons/md';
import {
  RiArrowGoBackLine,
  RiCheckFill,
  RiCloseLine,
  RiContactsLine,
  RiDashboardLine,
  RiDatabase2Line,
  RiDragMove2Line,
  RiGalleryFill,
  RiImageAddLine,
  RiMoneyEuroCircleLine
} from 'react-icons/ri';
import { SiAdobelightroom } from 'react-icons/si';
import {
  TiArrowDownThick,
  TiArrowUpThick,
  TiBell,
  TiBook,
  TiBriefcase,
  TiCalendarOutline,
  TiCameraOutline,
  TiChartBarOutline,
  TiChartPieOutline,
  TiChevronLeft,
  TiChevronRight,
  TiCogOutline,
  TiContacts,
  TiCreditCard,
  TiDocument,
  TiEdit,
  TiEye,
  TiHeartFullOutline,
  TiHomeOutline,
  TiInfoOutline,
  TiMail,
  TiPlusOutline,
  TiPrinter,
  TiSupport,
  TiTags,
  TiTrash,
  TiWarningOutline,
  TiZoom
} from 'react-icons/ti';
import { VscHome } from 'react-icons/vsc';

import { FontSize } from 'Themes';

import Text from './Text';

export const allowedIcons = [
  'add-file',
  'add-photo',
  'add',
  'admin',
  'archive',
  'ascending',
  'book',
  'bulb',
  'calendar',
  'campaigns',
  'cards',
  'cart',
  'check-circle',
  'check',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'close-circle',
  'close',
  'compta',
  'contacts-list',
  'contacts',
  'copy',
  'custom-field',
  'dashboard',
  'data',
  'delete',
  'delivery',
  'descending',
  'disable',
  'document',
  'download',
  'drag',
  'duplicate',
  'edit',
  'emails',
  'error',
  'exclamation',
  'exit',
  'external-link',
  'file',
  'financial-dashboard',
  'galleries',
  'gallery-app-cart',
  'gallery-app-comments',
  'gallery-app-heart',
  'gallery-app-home',
  'gallery-app-plus',
  'gallery-app-user',
  'globe',
  'google',
  'grid',
  'gridItems',
  'heart',
  'help',
  'home',
  'info-circle',
  'info',
  'invoice',
  'kanban',
  'lightroom',
  'list',
  'location',
  'message',
  'modules',
  'more',
  'my-activity',
  'newsletters',
  'notifications',
  'order-asc',
  'order-desc',
  'parrainage',
  'pdf',
  'photos',
  'plus',
  'preset',
  'price-template',
  'project-status',
  'project-type',
  'prospect-origins',
  'prospects',
  'purchased',
  'put-back',
  'random',
  'search',
  'send',
  'settings',
  'shootings',
  'shopping-bag',
  'show',
  'sidebar-collapse',
  'sidebar-expand',
  'sms',
  'star-outline-off',
  'star-outline',
  'star',
  'subscription',
  'table',
  'tags',
  'task-type',
  'tips',
  'upload',
  'user',
  'warning',
  'watermarks',
] as const;

export type IconName = typeof allowedIcons[number];

interface Props extends IconBaseProps {
  name: IconName;
  size?: number;
  color?: string;
  className?: string;
}

const iconMapping: { [key: string]: IconType } = {
  'add-file': AiOutlineFileAdd,
  'add-photo': RiImageAddLine,
  add: IoAdd,
  admin: MdOutlineAdminPanelSettings,
  archive: HiOutlineArchive,
  ascending: TiArrowUpThick,
  book: TiBook,
  bulb: GoLightBulb,
  calendar: TiCalendarOutline,
  campaigns: AiOutlineNotification,
  cards: TiCreditCard,
  cart: AiOutlineShoppingCart,
  'check-circle': AiFillCheckCircle,
  check: RiCheckFill,
  'chevron-down': IoChevronDown,
  'chevron-left': TiChevronLeft,
  'chevron-right': TiChevronRight,
  'chevron-up': IoChevronUp,
  'close-circle': AiFillCloseCircle,
  close: RiCloseLine,
  compta: TiChartBarOutline,
  'contacts-list': RiContactsLine,
  contacts: TiContacts,
  copy: FiCopy,
  'custom-field': CgViewList,
  dashboard: RiDashboardLine,
  data: RiDatabase2Line,
  delete: TiTrash,
  delivery: BsTruck,
  descending: TiArrowDownThick,
  disable: BiBlock,
  document: HiOutlineDocument,
  download: HiOutlineDownload,
  drag: RiDragMove2Line,
  duplicate: BiDuplicate,
  edit: TiEdit,
  emails: TiMail,
  error: BiErrorCircle,
  exclamation: AiOutlineExclamationCircle,
  exit: MdOutlineExitToApp,
  'external-link': HiExternalLink,
  file: AiOutlineFile,
  'financial-dashboard': TiChartPieOutline,
  'gallery-app-home': VscHome,
  'gallery-app-heart': HiOutlineHeart,
  'gallery-app-cart': BsCart,
  'gallery-app-plus': HiPlus,
  'gallery-app-user': AiOutlineUser,
  'gallery-app-comments': AiOutlineComment,
  galleries: RiGalleryFill,
  globe: BsGlobe2,
  google: AiOutlineGoogle,
  grid: BsUiChecksGrid,
  gridItems: BsGrid3X2Gap,
  heart: TiHeartFullOutline,
  help: TiSupport,
  home: TiHomeOutline,
  'info-circle': FaCircleInfo,
  info: TiInfoOutline,
  invoice: TiDocument,
  kanban: BsKanban,
  lightroom: SiAdobelightroom,
  list: BiListOl,
  location: HiOutlineLocationMarker,
  message: BiMessageRoundedDetail,
  modules: FaCubes,
  more: CgMore,
  'my-activity': TiBriefcase,
  newsletters: TiPrinter,
  notifications: TiBell,
  'order-asc': AiOutlineSortAscending,
  'order-desc': AiOutlineSortDescending,
  parrainage: FaRegHandshake,
  pdf: AiOutlineFilePdf,
  photos: IoMdPhotos,
  plus: TiPlusOutline,
  preset: BiPaint,
  'price-template': FaEuroSign,
  'project-status': GrStatusCriticalSmall,
  'project-type': IoMdPricetag,
  'prospect-origins': FaBinoculars,
  prospects: BiTargetLock,
  purchased: BsBoxSeam,
  'put-back': RiArrowGoBackLine,
  random: FaRandom,
  search: TiZoom,
  send: IoIosSend,
  settings: TiCogOutline,
  shootings: TiCameraOutline,
  'shopping-bag': MdOutlineShoppingBag,
  show: TiEye,
  'sidebar-collapse': GoSidebarCollapse,
  'sidebar-expand': GoSidebarExpand,
  sms: AiFillMessage,
  'star-outline-off': LuStarOff,
  'star-outline': LuStar,
  star: AiFillStar,
  subscription: RiMoneyEuroCircleLine,
  table: BsTable,
  tags: TiTags,
  'task-type': FaCheckSquare,
  tips: MdOutlineTipsAndUpdates,
  upload: FiUploadCloud,
  user: AiOutlineUser,
  warning: TiWarningOutline,
  watermarks: BiCopyright,
};

const Icon = ({ name, size = FontSize.regular, color, ...props }: Props) => {
  const IconEl = iconMapping[name];

  if (IconEl) {
    return <IconEl size={size} color={color} {...props} />;
  }
  return null;
};

export const IconText = ({ text, ...props }: Props & { text: string }) => (
  <Space>
    <Icon {...props} />
    <Text size="small">{text}</Text>
  </Space>
);

export default Icon;
