import { gql } from 'Operations/__generated__';

export const GET_INVOICES = gql(/* GraphQL */ `
  query GetInvoices($where: InvoicesGetInputType!) {
    getInvoices(where: $where) {
      _count
      edges {
        id
        title
        paid
        createdAt
        numero
        url
        ttcPrice
        contact {
          ...ContactCoreFields
        }
        gallery {
          id
          name
        }
      }
    }
  }
`);
